import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Page = () => (
  <Layout>
    <Seo title="Abonnement, betaling og prøveversjon" />
    <div className="longtext">
      <h2>Abonnement, betaling og prøveversjon</h2>
      <h3>Har dere en gratisversjon?</h3>
      <p>
        Ja, du kan bruke Mente gratis så lenge du vil (fra og med Mente 2)! Det
        er imidlertid noen begrensninger på bruk:
      </p>
      <ul>
        <li>Du kan ikke sette påminnelser på oppgaver</li>
        <li>Du kan maks ha 5 lister</li>
        <li>
          Du kan maks ha 5 steg i rutinene dine (totalt på tvers av alle
          rutiner)
        </li>
        <li>
          Du kan reflektere på inntil 1 spørsmål hver dag (og ikke på tidligere
          datoer)
        </li>
        <li>Fullførte oppgaver kan bli slettet etter en ukes tid</li>
      </ul>
      <h3>Hva får jeg med premium?</h3>
      <ul>
        <li>Påminnelser</li>
        <li>Så mange lister du vil</li>
        <li>Så mange steg i rutinene du vil</li>
        <li>Så mye refleksjon du vil</li>
        <li>Full historikk på utførte oppgaver</li>
      </ul>
      <h3>Kan jobben betale?</h3>
      <p>
        Siden abonnementet kjøpes via App Store på din egen AppleID, er det ikke
        direkte støtte for dette i dag. Men mange arbeidsgivere tillater at du
        kan utgiftsføre private utlegg og få de refundert, dersom de er nyttige
        i jobbsammenheng! Kostnaden av Mente er fort spart inn igjen for en
        arbeidsgiver, så vi vil anbefale å sjekke om dette er en mulighet for
        deg.
      </p>
      <h3>Hvordan avslutter jeg abonnement?</h3>
      <a href="https://support.apple.com/en-us/HT202039" target="_blank">
        Se Apple sin beskrivelse her
      </a>{" "}
      (det er ikke nok å bare slette appen).
    </div>
  </Layout>
)

export default Page
